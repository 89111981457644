import React from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  RefreshButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";
import EditActions from "./../actions/bookingEditActions";
import { SelectBundleDateButton } from "../actions/SelectBundleDateButton";
import { useRecordContext } from "react-admin";
import Link from "@mui/material/Link";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const BookingFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by id" source="qID" />
    <TextInput label="Search by buyer email" source="qBuyerEmail" alwaysOn />
    <TextInput label="Search by buyer name" source="qBuyerName" />
    <TextInput label="Search by school ID" source="qSchoolID" />
    {/*<TextInput label="Search by course title" source="qCourseTitle" />*/}
    <DateInput label="Search by Created date" source="qCreatedDate" />
    <SelectInput
      label="Search by B. Type"
      source="qBookingType"
      choices={[
        { id: "regular", name: "regular" },
        { id: "anytime", name: "anytime" },
        { id: "block", name: "block" },
        { id: "private", name: "private" },
        { id: "external", name: "external" }
      ]}
    />
    <TextInput
      label="Search by Stripe PaymentID"
      source="qstripePaymentIntentID"
    />
    <TextInput
      label="Search by Stripe TransferGroup ID"
      source="qstripeTransferGroupID"
    />
  </Filter>
);

export const BookingList = props => (
  <List
    {...props}
    filters={<BookingFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="buyerName" />
      <TextField label="Email" source="buyerEmail" />
      <TextField label="Phone" source="buyerPhone" />
      <TextField label="Course title" source="course.title" sortable={false} />
      <DateField
        label="Session date"
        source="session.startDateTime"
        locales="en-GB"
        sortable={false}
      />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
    </Datagrid>
  </List>
);

export const BookingCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput />
      <BooleanInput />
      <NumberInput />
    </SimpleForm>
  </Create>
);
var options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/London"
};

export const BookingEdit = props => (
  <Edit {...props} actions={<EditActions />}>
    <TabbedForm>
      <FormTab label="Booking info">
        <TextInput label="Id" source="id" disabled />
        <BooleanInput label="Is online?" source="isOnline" />
        <DateField label="Date of booking" source="createdAt" locales="en-GB" />
        <SelectInput
          label="Status"
          source="confirmation"
          choices={[
            { id: "", name: "" },
            { id: "confirmed", name: "confirmed" },
            { id: "rejected", name: "rejected" }
          ]}
        />
        <TextField label="Reject reason" source="rejectReason" />
        <BooleanInput source="cancelled" />
        <DateField
          label="Current Date"
          source="renderRealStartDate"
          locales="en-GB"
          options={options}
        />
        <DateField
          label="Previous Date"
          source="previousStartDateTime"
          locales="en-GB"
          options={options}
        />
        <DateField
          label="Original Start date"
          source="originalStartDateTime"
          locales="en-GB"
          options={options}
        />
        <TextInput label="Course title" source="course.title" />
        <TextField label="UTM" source="utm" />
        <BooleanInput source="emails.reminder" />
        <BooleanInput source="emails.review" />
        <NumberInput source="emails.reviewSentTimes" />
        <TextField label="Booking type" source="bookingType" />
        <TextField label="Booking source" source="source" />
        <TextField label="Schedule type (Appointments)" source="scheduleType" />
        <DateField
          label="Start date Block Booking"
          source="blockBookingStartDate"
          locales="en-GB"
        />
        <DateField label="Updated at" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Customer info">
        <TextInput source="buyerName" />
        <TextInput source="buyerEmail" />
        <TextInput source="buyerPhone" />
        <TextInput label="ID" source="buyerId" />
        <TextField label="Number of seats booked" source="totalAttendants" />
        <ArrayInput source="additionalGuests">
          <SimpleFormIterator>
            <TextInput source="firstname" />
            <TextInput source="surname" />
            <TextInput source="email" />
            <TextInput source="type" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Course info">
        <ReferenceField
          label="Course Link"
          source="course._id"
          reference="courses"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          label="School Link"
          source="school._id"
          reference="schools"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Session Link"
          source="session._id"
          reference="sessions"
        >
          <TextField source="startDateTime" />
        </ReferenceField>
      </FormTab>
      <FormTab label="Additional Classes">
        <ArrayField source="session.additionalClasses" label="">
          <Datagrid>
            <ReferenceField
              label="Session Id"
              source="sessionId"
              reference="sessions"
            >
              <TextField source="_id" />
            </ReferenceField>
            <TextField source="number" label="Number" />
            <DateField
              source="startDateTime"
              label="Start Date Time"
              showTime
              locales="en-gb"
            />
            <TextField source="duration" label="Duration" />
          </Datagrid>
        </ArrayField>
      </FormTab>
      <FormTab label="Customer Payment Info">
        <TextField label="Capture Method" source="captureMethod" />
        <TextInput label="Stripe payment ID" source="stripePaymentIntentID" />
        <NumberField
          label="Used credit"
          source="accounting.discountAmountUsed"
        />
        <TextInput label="Discount code" source="discount.code" />
        <TextInput label="Discount type" source="discount.type" />
        <TextInput label="Discount value" source="discount.value" />
        <ArrayField source="enabledTickets" label="Guests">
          <Datagrid>
            <TextField source="_id" />
            <TextField source="userInfo.firstname" label="User Firstname" />
            <TextField source="userInfo.surname" label="User Surname" />
            <TextField source="userInfo.email" label="User Email" />
            <NumberField source="ticket.price" />
            <TextField source="ticket.status" />
            <TextField source="ticket.name" />
            <BooleanField source="ticket.hasTimeConstraint" />
            <NumberField source="ticket.numberOfGuestsPerTicket" />
            <NumberField sourcce="ticket.minTicketsPerOrder" />
            <TextField source="ticket.visibility" />
            <BooleanField source="ticket.isVatIncluded" />
            <NumberField source="ticket.vat" />
          </Datagrid>
        </ArrayField>
        <ArrayField source="removedTickets" label="Removed guests">
          <Datagrid>
            <TextField source="_id" />
            <TextField source="userInfo.firstname" label="User Firstname" />
            <TextField source="userInfo.surname" label="User Surname" />
            <TextField source="userInfo.email" label="User Email" />
            <NumberField source="ticket.price" />
            <TextField source="ticket.status" />
            <TextField source="ticket.name" />
            <BooleanField source="ticket.hasTimeConstraint" />
            <NumberField source="ticket.numberOfGuestsPerTicket" />
            <NumberField sourcce="ticket.minTicketsPerOrder" />
            <TextField source="ticket.visibility" />
            <BooleanField source="ticket.isVatIncluded" />
            <NumberField source="ticket.vat" />
          </Datagrid>
        </ArrayField>
      </FormTab>
      <FormTab label="PartnerMoney">
        <TextField
          label="Stripe account type"
          source="stripeConnectAccountType"
        />
        <TextField
          label="Version of calculation of the platform fee"
          source="calculatedPlatformFeeVersion"
        />
        <ReferenceField
          label="Payout Link"
          source="payoutID"
          reference="payouts"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField
          label="Stripe Transfer Group ID"
          source="stripeTransferGroupID"
        />
        <TextField label="Teacher money status" source="partnerMoneyStatus" />
        <BooleanInput
          label="Is this booking paid? (DEPRECATED)"
          source="isPaid"
        />
        <TextField
          label="payoutStripeID"
          source="partnerMoneyTransfer.payoutStripeID"
        />
        <TextField
          label="paymentStripeID"
          source="partnerMoneyTransfer.paymentStripeID"
        />
        <TextField
          label="balanceTransactionStripeID"
          source="partnerMoneyTransfer.balanceTransactionStripeID"
        />
        <TextField
          label="transferStripeID"
          source="partnerMoneyTransfer.transferStripeID"
        />
        <DateField
          label="transferDate"
          source="partnerMoneyTransfer.transferDate"
        />
        <NumberField
          label="transferAmount"
          source="partnerMoneyTransfer.transferAmount"
        />
        <BooleanField
          label="isReversed"
          source="partnerMoneyTransfer.isReversed"
        />
        <DateField
          label="reverseDate"
          source="partnerMoneyTransfer.reverseDate"
        />
        <NumberField
          label="reverseAmount"
          source="partnerMoneyTransfer.reverseAmount"
        />
        <TextField
          label="reverseStripeID"
          source="partnerMoneyTransfer.reverseStripeID"
        />
      </FormTab>
      <FormTab label="Accounting">
        <BooleanInput
          label="Charge Partner with a cancellation fee?"
          source="chargeCancellationFee"
        />
        <NumberInput
          label="Specify commission just for this booking"
          source="commissionUsed"
        />
        <NumberInput
          label="Sales earning (pence)"
          source="accounting.rawEarning"
        />
        <NumberInput label="Net total (pence)" source="accounting.netPay" />
        <NumberInput
          label="TeacherPayout (pence)"
          source="accounting.profitEarning"
        />
        <NumberInput label="User Charged" source="accounting.userCharged" />
        <NumberInput
          label="Platform fee (pence)"
          source="accounting.platformFee"
        />
        <NumberInput
          label="Discount amount used"
          source="accounting.discountAmountUsed"
        />
        <NumberInput
          label="Obby margin (pence)"
          source="accounting.obbyMargin"
        />
        <NumberInput label="Obby Fee(pence)" source="accounting.obbyFee" />
        <NumberInput
          label="Payment processing (pence)"
          source="accounting.paymentProcessing"
        />
        <NumberInput label="VAT (pence)" source="accounting.vat" />
        <NumberInput
          label="Commission used for the calculations (percentage)"
          source="accounting.percentageCommissionUsed"
        />
        <NumberInput
          label="Net ticket price"
          source="accounting.netTicketPrice"
        />
        <NumberInput label="Ticket VAT" source="accounting.ticketVat" />
      </FormTab>
      <FormTab label="Delivery Address">
        <TextInput label="First name" source="deliveryAddress.firstName" />
        <TextInput label="SurName" source="deliveryAddress.lastName" />
        <TextInput
          label="Address line 1"
          source="deliveryAddress.addressLine1"
        />
        <TextInput
          label="Address line 2"
          source="deliveryAddress.addressLine2"
        />
        <TextInput label="Town/City" source="deliveryAddress.city" />
        <TextInput label="Country/Region" source="deliveryAddress.region" />
        <TextInput label="Postcode" source="deliveryAddress.postCode" />
        <TextInput label="Contact number" source="deliveryAddress.firstName" />
        <TextInput
          multiline
          fullWidth
          label="Additional notes"
          source="deliveryAddress.additionalNotes"
        />
      </FormTab>
      <FormTab label="Bundle">
        <TextInput label="Total tickets" source="bundleTotalTickets" />
        <DateField label="Bundle Expire date" source="bundleExpireDate" />
        <ReferenceField
          label="Bundle First session"
          source="bundleFirstSession"
          reference="sessions"
        >
          <TextField source="startDateTime" />
        </ReferenceField>
        <ExternalLinkField source="_id" />
        <ArrayField source="bundleRecords">
          <Datagrid>
            <DateField source="assignmentDate" />
            <TextField source="status" />
            <TextField source="confirmation" />
            <ReferenceField
              label="Session Link"
              source="session"
              reference="sessions"
            >
              <TextField source="startDateTime" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
        <SelectBundleDateButton />
      </FormTab>
      <FormTab label="Zoom detais">
        <TextInput
          label="Zoom Start URL"
          source="session.onlineProviderMeeting.start_url"
        />
        <TextInput
          label="Zoom Join URL"
          source="session.onlineProviderMeeting.join_url"
        />
      </FormTab>
      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
      <FormTab label="Miscelleneous">
        <TextInput label="Stripe Status" source="status" />
        <BooleanInput source="session.weekend" />
        <TextInput source="session.timeOfDay" />
        <NumberInput source="session.price" />
        <NumberInput source="session.totalAvailability" />
        <NumberInput source="session.availability" />
        <TextInput source="session.locationId" />
        <BooleanInput source="session.notified" />
        <TextInput source="session.location._id" disabled />
        <TextInput source='session.location."schoolId": "iD7Hn8JQ2yNkxLKXY",' />
        <BooleanInput source="session.location.archived" />
        <TextInput label="Lat" source="session.location.geolocation.lat" />
        <TextInput label="Lng" source="session.location.geolocation.lng" />
        <TextField label="Full stripe info" source="stripeResult" />
        <TextField label="Full course info" source="course" />
        <TextField label="Full school info" source="school" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const ExternalLinkField = ({ source }) => {
  const record = useRecordContext();
  console.log("the record ", record);
  let href = record && record[source];
  let school = record["school"];
  let schoolSlug = school["slug"];
  let bundleId = record["_id"];
  return (
    <Link
      target="_blank"
      href={`https://${schoolSlug}.live.baluu.co.uk/bookings/bundle/${bundleId}`}
    >
      Bundle Selection Link
    </Link>
  );
};
