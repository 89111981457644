import React from "react";
import {
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  SelectInput,
  ReferenceInput,
  SimpleForm,
  TextField,
  DateInput,
  TextInput,
  ReferenceField
} from "react-admin";
import PayoutEditActions from "./../actions/PayoutEditActions";

const PayoutFilters = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search by reference" source="reference" alwaysOn />
      <TextInput label="Search by school" source="school" alwaysOn />
      <SelectInput
        label="Search by Status"
        source="status"
        choices={[
          { id: "paid", name: "Paid" },
          { id: "pending", name: "Pending" },
          { id: "in_transit", name: "In Transit" },
          { id: "canceled", name: "Canceled" },
          { id: "failed", name: "Failed" }
        ]}
      />
      <ReferenceInput source="FilterByDate">
        <div>
          <DateInput helperText={false} source="startDate" />
          <DateInput helperText={false} source="endDate" />
        </div>
      </ReferenceInput>
    </Filter>
  );
};
export const PayoutList = props => {
  return (
    <List
      {...props}
      sort={{ field: "created", order: "DESC" }}
      filters={<PayoutFilters />}
    >
      <Datagrid rowClick="edit">
        <TextField source="_id" />
        <TextField label="Amount" source="amount" />
        <TextField source="reference" />
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="description" />
        <ReferenceField
          label="School/Business"
          source="schoolID"
          reference="schools"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField label="Arrival Date" source="arrivalDate" locales="en-GB" />
        <DateField label="Created Date" source="created" locales="en-GB" />
      </Datagrid>
    </List>
  );
};

export const PayoutEdit = props => (
  <Edit {...props} actions={<PayoutEditActions />}>
    <SimpleForm>
      <TextInput source="_id" disabled />
      <TextInput source="amount" disabled />
      <TextInput source="reference" disabled />
      <TextInput source="type" disabled />
      <SelectInput
        label="Status"
        source="status"
        choices={[
          { id: "paid", name: "Paid" },
          { id: "pending", name: "Pending" },
          { id: "in_transit", name: "In Transit" },
          { id: "canceled", name: "Canceled" },
          { id: "failed", name: "Failed" }
        ]}
      />
      <TextInput label="Statement Descriptor" source="description" />
      <DateField label="Arrival Date" source="arrivalDate" locales="en-GB" />
      <DateField label="Created Date" source="created" locales="en-GB" />
      <DateField label="Created at" source="createdAt" locales="en-GB" />
      <DateField label="Updated at" source="updatedAt" locales="en-GB" />
    </SimpleForm>
  </Edit>
);
