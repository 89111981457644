import React from "react";
import {
  BooleanField,
  BooleanInput,
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  List,
  RefreshButton,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";
import { JsonField, JsonInput } from "react-admin-json-view";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <RefreshButton />
  </CardActions>
);
const MessageFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const InternalMessageList = props => (
  <List {...props} filters={<MessageFilter />} actions={<PostActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="to" />
      <TextField source="subject" />
      <BooleanField source="isViewed" />
    </Datagrid>
  </List>
);

export const InternalMessageEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField disabled label="Id" source="id" />
      <TextField source="to" />
      <TextField source="subject" />
      <JsonField
        source="message"
        jsonString={true} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false
        }}
      />
      <BooleanInput source="isViewed" />
      <BooleanInput source="isSolved" />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
      <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
    </SimpleForm>
  </Edit>
);
