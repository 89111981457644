import React from "react";
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  RefreshButton,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import EditActions from "./../actions/productPurchasesEditActions";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const ProductPurchasesFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by id" source="qID" />
    <TextInput label="Search by buyer email" source="qBuyerEmail" alwaysOn />
    <TextInput label="Search by buyer name" source="qBuyerName" />
    <TextInput label="Search by school ID" source="qSchoolID" />
    <TextInput label="Search by product ID " source="qProductID" />
    <TextInput
      label="Search by Stripe PaymentID"
      source="qstripePaymentIntentID"
    />
    <TextInput
      label="Search by Stripe TransferGroup ID"
      source="qstripeTransferGroupID"
    />
  </Filter>
);

export const ProductPurchasesList = props => (
  <List
    {...props}
    filters={<ProductPurchasesFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="buyerName" />
      <TextField source="buyerEmail" />
      <ReferenceField
        label="Product Name"
        source="product"
        reference="products"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField
        label="Created Date"
        source="createdAt"
        locales="en-GB"
        showTime
      />
      <DateField
        source="dispatch.dispatchedAt"
        showTime
        locales="en-GB"
        label="Dispatched At"
      />
    </Datagrid>
  </List>
);

export const ProductPurchasesEdit = props => (
  <Edit {...props} actions={<EditActions />}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput disabled label="Id" source="id" />
        <TextField label="source" source="source" />
        <ReferenceField label="School Link" source="school" reference="schools">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Product Link"
          source="product"
          reference="products"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberInput source="quantity" />
        <NumberInput source="priceUsed" />
        <BooleanInput source="isAGift" label="Was selected as gift?" />
        <TextField label="Buyer ID" source="buyerId" />
        <TextField source="buyerEmail" />
        <TextField source="buyerName" />
        <TextField source="buyerPhone" />
        <TextField source="buyerAvatar" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Postage">
        <TextField source="postage.name" />
        <NumberField source="postage.price (pence)" />
        <NumberField
          label="Delivery from (days)"
          source="postage.deliveryTime.from"
        />
        <NumberField
          label="Delivery Up to (days)"
          source="postage.deliveryTime.to"
        />
      </FormTab>
      <FormTab label="Gifting">
        <TextInput source="giftingDetails.to" />
        <TextInput source="giftingDetails.from" />
        <TextInput multiline fullWidth source="giftingDetails.message" />
        <NumberField source="giftingDetails.giftingFeeUsed" />
      </FormTab>
      <FormTab label="User Inputs">
        <ArrayInput source="userInputs" label="Acquired Skills">
          <SimpleFormIterator>
            <TextField source="label" />
            <TextField source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <TextField source="userInputs" />
      </FormTab>
      <FormTab label="Discount/Credit">
        <NumberField source="usedCredit" />
        <TextField source="discount" />
      </FormTab>
      <FormTab label="Dispatch">
        <TextField label="Dispatch State" source="dispatch.status" />
        <TextField label="Dispatch Link" source="dispatch.trackingLink" />
        <DateField label="Dispatch at" source="dispatch.dispatchedAt" />
        <BooleanField
          label="Dispatch with customer email?"
          source="dispatch.wantsCustomerDispatchEmail"
        />
        <TextField
          label="Address Line1"
          source="deliveryAddress.addressLine1"
        />
        <TextField
          label="Address line 2"
          source="deliveryAddress.addressLine2"
        />
        <TextField label="City" source="deliveryAddress.city" />
        <TextField label="Region" source="deliveryAddress.region" />
        <TextField label="PostCode" source="deliveryAddress.postCode" />
        <TextField
          label="Contact number"
          source="deliveryAddress.contactNumber"
        />
        <TextField
          label="Additional Notes"
          source="deliveryAddress.additionalNotes"
        />
      </FormTab>
      <FormTab label="Cancelled">
        <BooleanInput label="Is Cancelled?" source="cancelled.isCancelled" />
        <BooleanInput label="Is Refunded?" source="cancelled.isRefunded" />
        <BooleanInput
          label="Is Cancelled trough Admin?"
          source="cancelled.isObbyCancelled"
        />
        <TextInput source="cancelled.reason" />
        <TextInput source="cancelled.reasonText" />
        <TextInput multiline fullWidth source="cancelled.cancellationMessage" />
        <DateField
          label="Cancelled at"
          source="cancelled.cancelledAt"
          locales="en-GB"
        />
        <DateField
          label="Refunded at"
          source="cancelled.refundedDate"
          locales="en-GB"
        />
      </FormTab>
      <FormTab label="PartnerMoney">
        <TextField
          label="Stripe account type"
          source="stripeConnectAccountType"
        />
        <TextField
          label="Version of calculation of the platform fee"
          source="calculatedPlatformFeeVersion"
        />
        <ReferenceField
          label="Payout Link"
          source="payoutID"
          reference="payouts"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField
          label="Stripe Transfer Group ID"
          source="stripeTransferGroupID"
        />
        <TextField label="Teacher money status" source="partnerMoneyStatus" />
        <BooleanInput
          label="Is this booking paid? (DEPRECATED)"
          source="isPaid"
        />
        <TextField
          label="payoutStripeID"
          source="partnerMoneyTransfer.payoutStripeID"
        />
        <TextField
          label="paymentStripeID"
          source="partnerMoneyTransfer.paymentStripeID"
        />
        <TextField
          label="balanceTransactionStripeID"
          source="partnerMoneyTransfer.balanceTransactionStripeID"
        />
        <TextField
          label="transferStripeID"
          source="partnerMoneyTransfer.transferStripeID"
        />
        <DateField
          label="transferDate"
          source="partnerMoneyTransfer.transferDate"
        />
        <NumberField
          label="transferAmount"
          source="partnerMoneyTransfer.transferAmount"
        />
        <BooleanField
          label="isReversed"
          source="partnerMoneyTransfer.isReversed"
        />
        <DateField
          label="reverseDate"
          source="partnerMoneyTransfer.reverseDate"
        />
        <NumberField
          label="reverseAmount"
          source="partnerMoneyTransfer.reverseAmount"
        />
        <TextField
          label="reverseStripeID"
          source="partnerMoneyTransfer.reverseStripeID"
        />
      </FormTab>
      <FormTab label="Accounting">
        <NumberInput source="accounting.userCharged" />
        <NumberInput
          label="Platform fee (pence)"
          source="accounting.platformFee"
        />
        <NumberInput source="accounting.discountAmountUsed" />
        <NumberInput source="accounting.rawEarning" />
        <NumberInput source="accounting.postageFee" />
        <NumberInput source="accounting.giftingFee" />
        <NumberInput source="accounting.profitEarning" />
        <NumberInput source="accounting.netPay" />
        <NumberInput source="accounting.paymentProcessing" />
        <NumberInput source="accounting.obbyMargin" />
        <NumberInput source="accounting.obbyFee" />
        <NumberInput source="accounting.vat" />
        <NumberInput source="accounting.percentageCommissionUsed" />
      </FormTab>
      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
