import React from "react";
import {
  Button,
  CardActions,
  CREATE,
  fetchEnd,
  fetchStart,
  showNotification,
  SaveButton
} from "react-admin";
import { connect } from "react-redux";
import { axiosInstance } from "../config/axios";
import dataProvider from "../dataProvider";
import { MarkSchoolAsSoldOutButton } from "../actions/MarkSchoolAsSoldOutButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconCancel from "@material-ui/icons/Cancel";

class PayoutActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      showDialog: false,
      isSubmitting: false
    };
  }

  onSyncStripePayoutsWithOrders() {
    this.update("sync-payoutdb-with-order");
  }

  render() {
    const { updating } = this.state;
    return (
      <CardActions>
        <Button
          disabled={updating}
          onClick={this.onSyncStripePayoutsWithOrders.bind(this)}
          label="Sycn Stripe Payouts with Orders"
        />
      </CardActions>
    );
  }

  update(action) {
    this.setState({
      updating: true
    });
    fetchStart();
    dataProvider(CREATE, `actions/${action}`, {
      data: { payoutID: this.props.data._id }
    })
      .then(response => {
        this.props.showNotification("Updated successfully", "success");
        window.location.reload();
      })
      .catch(error => {
        this.props.showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
        this.setState({
          updating: false
        });
      });
  }
}

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification
};

export default connect(null, mapDispatchToProps)(PayoutActions);
