import * as Icons from "@material-ui/icons";
import { DashboardPermissionsLabels } from "../constants/DashboardPermissionsLabels";
import React, { useState } from "react";
import {
  ArrayInput,
  BooleanInput,
  Button,
  CardActions,
  Create,
  CREATE,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  NumberInput,
  required,
  SelectInput,
  SelectArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  CreateButton,
  RefreshButton
} from "react-admin";
import dataProvider from "../dataProvider";

const TeacherPlanListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
    <TextInput label="Search by name" source="qName" alwaysOn />
  </Filter>
);

function TeacherPlanListActions({ basePath }) {
  const [assigning, setAssigning] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  async function onAssignNewPermissions() {
    setAssigning(true);

    try {
      await dataProvider(CREATE, `actions/assign-new-permissions`, {
        data: {}
      });
      notify("New permissions assigned successfully");
      refresh();
    } catch (e) {
      notify(e.message, "error");
    } finally {
      setAssigning(false);
    }
  }

  return (
    <CardActions>
      <CreateButton basePath={basePath} />
      <Button
        disabled={assigning}
        onClick={onAssignNewPermissions}
        label={"Assign new permissions"}
      />
      <RefreshButton />
    </CardActions>
  );
}

export function TeacherPlanList(props) {
  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "ASC" }}
      filters={<TeacherPlanListFilter />}
      actions={<TeacherPlanListActions />}
      empty={false}
    >
      <Datagrid rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Name" source="name" />
        <TextField label="Small description" source="description" />
        <CheckField label="Public" source="public" />
        <CheckField label="Sign-Up Default" source="default" />
        <CheckField label="Trial End Default" source="trialEndDefault" />
        <PricesField label="Prices" source="prices" />
        <TextField label="Companies" source="companies" />
      </Datagrid>
    </List>
  );
}

function CheckField({ record = {}, source } = {}) {
  return <span>{!!record[source] && <Icons.Check fontSize="small" />}</span>;
}
//
function PricesField({ record = {}, source } = {}) {
  const { prices = {} } = record;
  const { monthly = null, yearly = null } = prices;
  const labels = [];
  if (monthly !== null) labels.push(`£${monthly / 100} pm`);
  if (yearly !== null) labels.push(`£${yearly / 100} pa`);

  return <span>{labels.join(" / ")}</span>;
}

export function TeacherPlanCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="Name" validate={[required()]} />
        <TextInput source="description" label="Small description" />
        <BooleanInput source="public" label="Public" />
        <SelectArrayInput
          label="Companies"
          source="companies"
          choices={[
            { id: "obby", name: "Obby" },
            { id: "baluu", name: "Baluu" }
          ]}
        />
        <BooleanInput
          source="default"
          label="Sign-Up Default"
          helperText="This is the plan that will be chosen for the user automatically during sign up. Note: Whilst you can choose more than one default. Only one from Obby and one from baluu will be used during sign up."
        />
        <BooleanInput
          source="trialEndDefault"
          label="Trial End Default"
          helperText="User's will be demoted to this plan if their trial ends. Note: Whilst you can choose more than one default. Only one from Obby and one from baluu will be used during sign up."
        />
        <NumberInput source="order" label="Order" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
        <NumberInput source="prices.monthly" label="Price per month" />
        <NumberInput source="prices.yearly" label="Price per month (yearly)" />
      </SimpleForm>
    </Create>
  );
}

export function TeacherPlanEdit(props) {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Details">
          <TextInput
            source="name"
            label="Name"
            validate={[required()]}
            parse={v => v.trim()}
          />
          <TextInput source="description" label="Small description" />
          <BooleanInput source="public" label="Public" />
          <SelectArrayInput
            label="Companies"
            source="companies"
            choices={[
              { id: "obby", name: "Obby" },
              { id: "baluu", name: "Baluu" }
            ]}
          />
          <BooleanInput
            source="default"
            label="Sign-Up Default"
            helperText="This is the plan that will be chosen for the user automatically during sign up. Note: Whilst you can choose more than one default. Only one from Obby and one from baluu will be used during sign up."
          />
          <BooleanInput
            source="trialEndDefault"
            label="Trial End Default"
            helperText="User's will be demoted to this plan if their trial ends. Note: Whilst you can choose more than one default. Only one from Obby and one from baluu will be used during sign up."
          />
          <NumberInput source="order" label="Order" />
          <DateField label="Created Date" source="createdAt" locales="en-GB" />
          <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
          <NumberInput source="prices.monthly" label="Price per month" />
          <NumberInput
            source="prices.yearly"
            label="Price per month (yearly)"
          />
        </FormTab>
        <FormTab label="Stripe Setup">
          <ArrayInput source="subscriptionsPrices">
            <SimpleFormIterator>
              <SelectInput
                source="billingPeriod"
                label="Billing period"
                choices={[
                  { id: "monthly", name: "monthly" },
                  { id: "yearly", name: "yearly" }
                ]}
              />
              <NumberInput source="price" label="Price in pence" />
              <TextInput source="stripePriceID" label="Stripe Price ID" />
              <TextInput source="stripeTaxRateID" label="Stripe Tax Rate ID" />
              <SelectInput
                source="taxBehaviour"
                label="Tax Behaviour"
                choices={[
                  { id: "included", name: "included" },
                  { id: "excluded", name: "excluded" }
                ]}
              />
              <SelectInput
                source="status"
                label="Status"
                choices={[
                  { id: "active", name: "active" },
                  { id: "disabled", name: "disabled" }
                ]}
                initialValue={"active"}
              />
              <TextInput
                source="schoolID"
                label="SchoolID associated to this price. ( mostly for entreprise)"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Commissions">
          <NumberInput
            source="bookingCommission"
            label="Marketplace Commission (%)"
          />
          <NumberInput
            source="groupBookingCommission"
            label="Group Booking Commission (%)"
          />
          <NumberInput
            source="widgetCommission"
            label="Widget Commission (%)"
          />
          <NumberInput
            source="onlineCommission"
            label="Online Commission (%)"
          />
        </FormTab>
        <FormTab label="Transfer Rules">
          <NumberInput
            source="transferMoneyRules.marketplaceBookings"
            label="Marketplace Bookings (days)"
            min={0}
          />
          <SelectInput
            label="Marketplace Bookings Transfer settings"
            source="transferMoneyRules.marketplaceBookingsTransferStrategy"
            choices={[
              { id: "afterEvent", name: "afterEvent" },
              { id: "afterPurchase", name: "afterPurchase" }
            ]}
          />
          <NumberInput
            source="transferMoneyRules.marketplaceProducts"
            label="Marketplace Products (days)"
            min={0}
          />
          <SelectInput
            label="Marketplace Products Transfer settings"
            source="transferMoneyRules.marketplaceProductsTransferStrategy"
            choices={[
              { id: "afterEvent", name: "afterEvent" },
              { id: "afterPurchase", name: "afterPurchase" }
            ]}
          />
          <NumberInput
            source="transferMoneyRules.widgetBookings"
            label="Widget Bookings (days)"
            min={0}
          />
          <SelectInput
            label="Widget Bookings Transfer settings"
            source="transferMoneyRules.widgetBookingsTransferStrategy"
            choices={[
              { id: "afterEvent", name: "afterEvent" },
              { id: "afterPurchase", name: "afterPurchase" }
            ]}
          />
          <NumberInput
            source="transferMoneyRules.widgetProducts"
            label="Widget Products (days)"
            min={0}
          />
          <SelectInput
            label="Widget Products Transfer settings"
            source="transferMoneyRules.widgetProductsTransferStrategy"
            choices={[
              { id: "afterEvent", name: "afterEvent" },
              { id: "afterPurchase", name: "afterPurchase" }
            ]}
          />
          <NumberInput
            source="transferMoneyRules.widgetGiftCards"
            label="Widget Giftcards (days)"
            min={0}
          />
          <NumberInput
            source="transferMoneyRules.secureBookings"
            label="Secure Bookings (days) - Old way to have a secure payment"
            min={0}
          />
        </FormTab>
        <FormTab label="Features">
          <ArrayInput source="featuresLabelled" label="">
            <SimpleFormIterator>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Payout settings">
          <SelectInput
            label="interval"
            source="payoutScheduleSettings.interval"
            choices={[
              { id: "daily", name: "daily" },
              { id: "weekly", name: "weekly" },
              { id: "monthly", name: "monthly" }
            ]}
          />
          <NumberInput
            source="payoutScheduleSettings.monthly_anchor"
            label="monthly_anchor"
            min={1}
            max={31}
          />
          <SelectInput
            label="weekly_anchor"
            source="payoutScheduleSettings.weekly_anchor"
            choices={[
              { id: null, name: "None" },
              { id: "monday", name: "monday" },
              { id: "tuesday", name: "tuesday" },
              { id: "wednesday", name: "wednesday" },
              { id: "thursday", name: "thursday" },
              { id: "friday", name: "friday" },
              { id: "saturday", name: "saturday" },
              { id: "sunday", name: "sunday" }
            ]}
          />
          <div>
            Making changes here will affect school that are slected to this plan
            after the change. If we want to update the school of this plan prior
            to changes we need an button that trigger sync.
          </div>
        </FormTab>
        <FormTab label="Permissions">
          <ArrayInput source="permissions" label="">
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...props }) => (
                  <BooleanInput
                    source={getSource("value")}
                    {...props}
                    label={
                      DashboardPermissionsLabels[scopedFormData.name] ||
                      scopedFormData.name
                    }
                  />
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
